import { Link } from 'gatsby'
import React, { Component } from 'react'
import posed from 'react-pose'
import styled from 'styled-components'
import Arrow from '../../../images/icon-long-arrow.svg'

const NavUL = posed.ul({
  open: {
    delayChildren: 500,
    staggerChildren: 80,
  },
})

const Item = posed.li({
  open: { x: 0, opacity: 1 },
  closed: { x: -100, opacity: 0 },
})

const NavWrapper = styled.div`
  display: grid;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      margin: 0px;
    }
    a {
      cursor: pointer;
      color: ${props => props.theme.white};
      font-family: ${props => props.theme.poppins};
      font-size: 1.7rem;
      position: relative;
      transition: all 0.2s ease-in-out;
      padding: 15px 15px 10px 25px;
      display: inline-block;
      &:hover {
        padding-left: 5px;
        padding-right: 10px;
      }
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: ${props => props.colour};
        visibility: hidden;
        transform: scaleX(0);
        transform-origin: 0;
        transition: all 0.2s ease-in-out;
      }
      &:hover:before {
        visibility: visible;
        transform: scaleX(1);
      }
      &[aria-current='page'] {
        color: ${props => props.colour};
      }
    }
  }
  &.compact ul li {
    margin: 0 !important;
  }
  @media (max-width: 600px) {
    text-align: center;
  }
`

class NavItems extends Component {
  render() {
    const { isOpen, toggle, colour } = this.props

    let classes = ['nav-wrapper']

    return (
      <NavWrapper
        className={classes.join(' ')}
        activeArrow={Arrow}
        colour={colour}
      >
        <NavUL className="links" pose={isOpen ? 'open' : 'closed'}>
          <Item>
            <Link to="/" onClick={toggle}>
              Home
            </Link>
          </Item>
          <Item>
            <Link to="/about" onClick={toggle}>
              About
            </Link>
          </Item>
          <Item>
            <Link to="/work" onClick={toggle}>
              Work
            </Link>
          </Item>
          <Item>
            <Link to="/articles" onClick={toggle}>
              Articles
            </Link>
          </Item>
          <Item>
            <Link to="/tools" onClick={toggle}>
              Tools
            </Link>
          </Item>
          <Item>
            <Link to="/contact" onClick={toggle}>
              Contact
            </Link>
          </Item>
        </NavUL>
      </NavWrapper>
    )
  }
}
export default NavItems
