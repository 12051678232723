import React from 'react'
import styled from 'styled-components'
import SocialIcons from '../shared/socialIcons'

const FooterWrapper = styled.footer`
  background-color: ${props => (props.lightMode ? '#15081e' : null)};
  border-top: 1px solid
    ${props =>
      props.lightMode ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.15)'};
  margin-top: -90px;
  padding: 25px;
  @media (max-width: 480px) {
    margin-top: -90px;
    background: #15081e;
  }
`
const FooterInner = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.6);
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  .notice {
    justify-self: end;
    a {
      color: rgba(255, 255, 255, 0.6);
      text-decoration: underline;
      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .social-icons {
    display: grid;
    grid-template-columns: repeat(6, 40px);
    align-items: center;
    text-align: center;
    a {
      transition: all 0.2s ease-in-out;
      padding: 5px;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      min-height: 40px;
      max-width: 40px;
    }
    a:hover {
      transform-origin: center center;
      transform: scale(1.2);
      background: rgba(255, 255, 255, 0.1);
    }
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    justify-items: center;
    grid-gap: 15px;
    .notice {
      justify-self: center;
    }
  }
`

const Footer = ({ lightMode }) => (
  <FooterWrapper lightMode={lightMode}>
    <FooterInner lightMode={lightMode}>
      <SocialIcons />
      <div className="notice">
        Hosting by{' '}
        <a rel="nofollow" href="https://kinsta.com/signup/?kaid=YBLSVXCTSZLG">
          Kinsta
        </a>
        &nbsp;&nbsp;|&nbsp;&nbsp; &copy; {new Date().getFullYear()} Francois
        Brill{' '}
      </div>
    </FooterInner>
  </FooterWrapper>
)

export default Footer
