import React, { Component } from 'react'
import posed from 'react-pose'
import styled from 'styled-components'
import SocialIcons from '../../shared/socialIcons'
import NavItems from './NavItems'

const SocialIconsWrapper = posed.div({
  open: { y: 0, opacity: 1, delay: 1200 },
  closed: { y: 200, opacity: 0 },
})

const Drawer = styled.div`
  background: ${props => props.theme.dark};
  color: ${props => props.theme.white};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  transition: all 0.2s cubic-bezier(0.19, 0.61, 1, 0.31);
  .nav-wrapper {
    width: 70vw;
  }
  &.closed {
    max-height: 0;
    overflow: hidden;
  }
  &.open {
    display: block;
    max-height: 100vh;
  }
`
const Inner = styled.div`
  margin: auto auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr 150px;
  justify-items: center;
  align-items: flex-start;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  .nav-wrapper {
    text-align: center;
    align-self: center;
  }
`

class NavigationDrawer extends Component {
  render() {
    const { isOpen, toggle, colour } = this.props

    const classes = ['main-nav']
    if (isOpen) {
      classes.push('open')
    } else classes.push('closed')

    return (
      <Drawer className={classes.join(' ')}>
        <Inner>
          <div />
          <NavItems toggle={toggle} isOpen={isOpen} colour={colour} />
          <SocialIconsWrapper pose={isOpen ? 'open' : 'closed'}>
            <SocialIcons colour={colour} inNav />
          </SocialIconsWrapper>
        </Inner>
      </Drawer>
    )
  }
}

export default NavigationDrawer
