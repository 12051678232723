import React from 'react'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import PropTypes from 'prop-types'
import Footer from './footer/footer'
import './layout.css'

const theme = {
  white: '#fff',
  dark: '#15081E',
  lime: '#A5ED7E',
  yellow: '#F5D123',
  gold: '#F5A623',
  mint: '#50E3C2',
  red: '#D0021B',
  maxWidth: '1260px',
  maxWidthSkinny: '800px',
  gutter: '15px',
  fontColor: '#515155',
  poppins: '"Poppins", "Arial Black", sans-serif',
  openSans: '"Open Sans", Arial, Helvetica, sans-serif',
  vPadding: '60px',
}

const StyledPage = styled.div`
  background: ${props => (props.lightMode ? '#fff' : '#15081E')};
  color: ${props => props.theme.white};
  min-height: 100vh;
`

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Poppins:400,600, 700');
  html {
    box-sizing: border-box;
    font-size: 16px;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    padding: 0;
    margin: 0;
    font-weight:300;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  }
  h1, h2, h3 , h4, h5, h6 {
    font-family: "Poppins", "Arial Black", sans-serif;
    font-weight: normal;
    font-style: normal;
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    color: ${theme.dark};
  }
  img {
    max-width: 100%;
    height:auto;
  }
  .headroom-wrapper {
    position: relative;
    z-index:999;
  }
  .headroom--unpinned {
    transform:translate3d(0px, -112%, 0px) !important;
  }
`

const Layout = ({ children, lightMode }) => (
  <ThemeProvider theme={theme}>
    <StyledPage lightMode={lightMode}>
      <GlobalStyle />
      <main>{children}</main>
      <Footer lightMode={lightMode} />
    </StyledPage>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
