import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Navigation from './navigation/navigation'
import NavigationDrawer from './navigation/NavigationDrawer'
import SocialIcons from '../shared/socialIcons'
import NavIcon from './navigation/navIcon'
import Headroom from 'react-headroom'

const HeaderWrapper = styled.header`
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px 0 0 0;
  height: 94px;
  z-index: 999;
  position: sticky;
  top: 0;
  transition: all 0.4s ease-in-out;
  background-color: ${props =>
    props.hasScrolled ? 'rgba(17,17,27,0.6)' : 'transparent'};
  backdrop-filter: blur(2px);
`
const HeaderInner = styled.div`
  color: ${props => (props.lightMode ? '#15081E' : '#fff')};
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 300px 1fr;
  position: relative;
  z-index: 9;
  nav {
    justify-self: end;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr 100px;
    padding: 0 0 0 20px;
  }
  @media (max-width: 400px) {
    grid-template-columns: 1fr 80px;
  }
  @media (max-width: 320px) {
    padding: 0 10px;
  }
`
const Logo = styled(Link)`
  margin: 0 0 -18px 0;
  display: block;
  #Home {
    stroke: ${props => props.colour};
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    #Home {
      stroke-width: 5;
    }
  }
`

const LogoWrapper = styled.div`
  display: grid;
  grid-template-columns: 75px 1fr;
  grid-gap: 20px;
  .name {
    font-size: 20px;
    font-weight: 600;
    padding-top: 12px;
    padding-left: 6px;
    text-transform: uppercase;
    color: ${props => (props.lightMode ? '#636363' : '#fff')};
    .last {
      color: ${props => props.colour};
    }
  }
  .social-icons {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    justify-items: center;
    width: 170px;
    img {
      max-width: 17px;
      max-height: 16px;
    }
    a {
      padding: 6px;
      opacity: 0.8;
      transition: all 0.2s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
    .email img {
      position: relative;
      bottom: -2px;
    }
    .dribble img {
      position: relative;
      bottom: -1px;
    }
  }
  @media (max-width: 400px) {
    grid-template-columns: 60px 1fr;
    grid-gap: 10px;
  }
`
const NavigationWrapper = styled.div`
  display: contents;
  @media (max-width: 900px) {
    display: none;
  }
`
const HeaderContainerWrapper = styled.div`
  .headroom--unpinned {
    transform: translate3d(
      0px,
      ${props => (props.showMobileMenu ? '0px' : '-112%')},
      0px
    ) !important;
  }
`

class Header extends Component {
  state = {
    hasScrolled: false,
    showMobileMenu: false,
  }
  componentDidMount() {
    // Scroll Position
    window.addEventListener('scroll', this.listenToScroll)
  }
  mobileMenuToggleHandler = () => {
    this.setState(prevState => {
      return { showMobileMenu: !prevState.showMobileMenu }
    })
  }
  mobileMenuHideHandler = () => {
    this.setState({
      showMobileMenu: false,
    })
  }
  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    if (winScroll > 80) {
      this.setState({
        hasScrolled: true,
      })
    } else {
      this.setState({
        hasScrolled: false,
      })
    }
  }

  render() {
    const { colour, lightMode } = this.props
    const { hasScrolled, showMobileMenu } = this.state
    return (
      <HeaderContainerWrapper showMobileMenu={showMobileMenu}>
        <Headroom>
          <HeaderWrapper hasScrolled={hasScrolled} lightMode={lightMode}>
            <HeaderInner lightMode={lightMode}>
              <LogoWrapper colour={colour} lightMode={lightMode}>
                <Logo colour={colour} to="/">
                  <svg
                    width="70px"
                    height="96px"
                    viewBox="0 0 70 96"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Francois Brill</title>
                    <desc>Created with Sketch.</desc>
                    <g
                      id="Layouts"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <g
                        id="Home"
                        transform="translate(-48.000000, -17.000000)"
                        stroke="#A5ED7E"
                        strokeWidth="3"
                      >
                        <g
                          id="//-Header"
                          transform="translate(0.000000, 18.000000)"
                        >
                          <g id="fb" transform="translate(50.000000, 1.000000)">
                            <path
                              d="M0,51.4372328 C3.79823213,50.9484971 7.98981532,49.4282095 12.5747496,46.8763698 C16.9749797,44.427331 21.826461,41.8380359 30.519886,34.5370983 C37.8689091,28.3652202 53.3133046,21.7579004 57.793027,16.7041618 C67.2580964,6.02627113 59.2798046,-4.94396634 50.1615882,2.37355759 C43.0167123,8.10744209 34.5040589,19.9348393 29.9736981,33.8671644 C28.7224595,37.715128 27.7861698,39.9733452 27.2528345,44.427331 C24.7884517,65.0078671 35.993119,80.0374997 49.2847325,73.4195851 C62.576346,66.8016704 50.23397,49.9552148 43.2340174,43.8828895 C38.5673823,39.8346726 34.3293385,36.7194089 30.519886,34.5370983 C37.9529447,34.5981758 45.2428292,34.0837097 52.3895394,32.9936999 C59.5362496,31.9036901 64.0730698,30.2626618 66,28.0706149"
                              id="Path"
                            />
                            <path
                              d="M13.4736388,92 C16.4912129,84.6666667 18.755667,77.7394537 20.2670011,71.2183611 C21.2524666,66.9662817 24.9782115,51.9966663 28.8714898,36.4765582 C31.3695337,26.5183921 34.6130076,15.9041598 35.5114806,10.746536 C37.8102513,-2.44940303 31.4879864,-0.57199009 27.1324208,1.37871394 C22.7768553,3.32941798 9,16.2083903 9,26.6503943 C9,37.0923982 20.5990725,40.6074608 27.1324208,36.4765582 C31.4879864,33.7226231 34.0341008,32.1969861 34.7707641,31.8996472"
                              id="Path-2"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </Logo>
                <div>
                  <div className="name">
                    Francois <span className="last">Brill</span>
                  </div>
                  <SocialIcons lightMode={lightMode} />
                </div>
              </LogoWrapper>
              <NavIcon
                mobileMenuToggleHandler={this.mobileMenuToggleHandler}
                open={this.state.showMobileMenu}
                colour={colour}
                lightMode={lightMode}
              />
              <NavigationWrapper>
                <Navigation colour={colour} lightMode={lightMode} />
              </NavigationWrapper>
            </HeaderInner>
            <NavigationDrawer
              isOpen={this.state.showMobileMenu}
              toggle={this.mobileMenuToggleHandler}
              colour={colour}
            />
          </HeaderWrapper>
        </Headroom>
      </HeaderContainerWrapper>
    )
  }
}

Header.propTypes = {
  colour: PropTypes.string.isRequired,
}

Header.defaultProps = {
  colour: '#FFFFFF',
}

export default Header
