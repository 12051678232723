import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const NavigationWrapper = styled.nav`
  a {
    color: ${props => (props.lightMode ? '#636363' : '#fff')};
    padding: 15px 5px 30px 5px;
    margin: 15px 0 15px 30px;
    display: inline-block;
    font-weight: 400;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    &[aria-current='page'],
    &.current {
      border-bottom: 3px solid ${props => props.colour};
      font-weight: 600;
      color: ${props => props.colour};
      &:hover {
        color: ${props => props.colour};
        border-bottom: 3px solid ${props => props.colour};
      }
    }
    &:hover {
      border-bottom: 3px solid #fff;
      color: #fff;
    }
  }
  @media (max-width: 1024px) {
    a {
      padding: 15px 0 30px 0;
    }
  }
`

const Navigation = ({ colour, lightMode }) => (
  <NavigationWrapper colour={colour} lightMode={lightMode}>
    <Link to="/">Home</Link>
    <Link to="/about" className={colour === '#F5D123' ? 'current' : ''}>
      About
    </Link>
    <Link to="/work" className={colour === '#F5A623' ? 'current' : ''}>
      Work
    </Link>
    <Link to="/articles">Articles</Link>
    <Link to="/tools" className={colour === '#FF8862' ? 'current' : ''}>
      Tools
    </Link>
    <Link to="/contact">Contact</Link>
  </NavigationWrapper>
)

Navigation.propTypes = {
  colour: PropTypes.string.isRequired,
}

Navigation.defaultProps = {
  colour: '#FFFFFF',
}

export default Navigation
