import React from 'react'
import SmallIconEmail from '../../images/icon-email.svg'
import SmallIconAngelCo from '../../images/icon-angel-co.svg'
import SmallIconTwitter from '../../images/icon-twitter.svg'
import SmallIconLinkedIn from '../../images/icon-linkedin.svg'
import SmallIconMedium from '../../images/icon-medium.svg'
import SmallIconDribble from '../../images/icon-dribble.svg'
import LargeIconLinkedIn from '../../images/icon-social-linkedin.svg'
import LargeIconDribble from '../../images/icon-social-dribble.svg'
import LargeIconMedium from '../../images/icon-social-medium.svg'
import LargeIconAngelCo from '../../images/icon-angel-co-large.svg'
import LargeIconTwitter from '../../images/icon-social-twitter.svg'
import styled from 'styled-components'

const SocialIWrapper = styled.div`
  &.large {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin: 20px 0 50px 0;
    img {
      width: 60px;
      height: 60px;
    }
    a {
      opacity: 0.7;
      transform: scale(1, 1);
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: scale(1.1, 1.1);
        opacity: 1;
      }
    }
  }
  &.in-nav {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: 55vw;
    max-width: 400px;
    grid-gap: 10px;
    align-items: center;
    justify-items: center;
    a {
      opacity: 0.7;
      transform: scale(1, 1);
      transition: all 0.2s ease-in-out;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 5px;
      width: 45px;
      height: 45px;
      padding: 13px;
      text-align: center;
      &:hover {
        background: ${props => props.colour};
        transform: scale(1.1, 1.1);
        opacity: 1;
      }
    }
  }
  @media (max-width: 600px) {
    &.in-nav {
      width: 85vw;
    }
    &.large {
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 20px;
      margin: 20px 0;
      img {
        width: 40px;
        height: 40px;
      }
      .linkedin {
        grid-row: 1;
        grid-column: 1 / span 2;
      }
      .medium {
        grid-row: 1;
        grid-column: 3 / span 2;
      }
      .dribble {
        grid-row: 1;
        grid-column: 5 / span 2;
      }
      .angel {
        grid-row: 2;
        grid-column: 2 / span 2;
      }
      .twitter {
        grid-row: 2;
        grid-column: 4 / span 2;
      }
    }
  }
`

const SocialIcons = ({ isLarge, inNav, colour, lightMode }) => {
  let classes = ['social-icons']
  if (isLarge) {
    classes = [...classes, 'large']
  }
  if (inNav) {
    classes = [...classes, 'in-nav']
  }
  return (
    <SocialIWrapper
      className={classes.join(' ')}
      colour={colour}
      lightMode={lightMode}
    >
      {!isLarge && (
        <a
          href="mailto:f@brill.co.za"
          target="_blank"
          rel="noopener noreferrer"
          className="email"
        >
          <img src={SmallIconEmail} alt="Email Me" />
        </a>
      )}
      <a
        href="https://www.linkedin.com/in/fbrill"
        target="_blank"
        rel="noopener noreferrer"
        className="linkedin"
      >
        <img
          src={isLarge ? LargeIconLinkedIn : SmallIconLinkedIn}
          alt="Find me on LinkedIn"
        />
      </a>
      <a
        href="https://medium.com/@fbrill"
        target="_blank"
        rel="noopener noreferrer"
        className="medium"
      >
        <img
          src={isLarge ? LargeIconMedium : SmallIconMedium}
          alt="Find me on Medium"
        />
      </a>
      <a
        href="https://dribbble.com/fbrill"
        target="_blank"
        rel="noopener noreferrer"
        className="dribble"
      >
        <img
          src={isLarge ? LargeIconDribble : SmallIconDribble}
          alt="Find me on Dribble"
        />
      </a>
      <a
        href="https://angel.co/francois-brill"
        target="_blank"
        rel="noopener noreferrer"
        className="angellist"
      >
        <img
          src={isLarge ? LargeIconAngelCo : SmallIconAngelCo}
          alt="Find me on AngelList"
        />
      </a>
      <a
        href="https://twitter.com/fbrill"
        target="_blank"
        rel="noopener noreferrer"
        className="twitter"
      >
        <img
          src={isLarge ? LargeIconTwitter : SmallIconTwitter}
          alt="Find me on Twitter"
        />
      </a>
    </SocialIWrapper>
  )
}

export default SocialIcons
