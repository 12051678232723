import React from 'react'
import styled from 'styled-components'

const IconWrapper = styled.div`
  display: none;
  padding: 15px;
  background: rgba(0, 0, 0, 0);
  transition: background 400ms ease-in-out;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  top: -10px;
  height: calc(100% + 10px);
  &:hover {
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  &.open {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
  }
  @media (max-width: 400px) {
    padding: 15px 5px;
  }
`

const Icon = styled.div`
  margin: 0;
  padding: 0;
  width: 30px;
  height: 25px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  &.open span {
    background: ${props => props.colour};
  }
  span:nth-child(1) {
    top: 0px;
  }
  span:nth-child(2),
  span:nth-child(3) {
    top: 10px;
  }
  span:nth-child(4) {
    top: 20px;
  }
  &.open span:nth-child(1) {
    top: 10px;
    width: 0%;
    left: 50%;
  }
  &.open span:nth-child(2) {
    transform: rotate(45deg);
  }
  &.open span:nth-child(3) {
    transform: rotate(-45deg);
  }
  &.open span:nth-child(4) {
    top: 10px;
    width: 0%;
    left: 50%;
  }
`

function NavIcon({ open, colour, mobileMenuToggleHandler }) {
  const classes = ['nav-icon']
  if (open) {
    classes.push('open')
  }
  return (
    <IconWrapper
      className={classes.join(' ')}
      onClick={mobileMenuToggleHandler}
    >
      <Icon className={classes.join(' ')} colour={colour}>
        <span />
        <span />
        <span />
        <span />
      </Icon>
    </IconWrapper>
  )
}

export default NavIcon
